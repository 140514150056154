<template>
  <div class="container watermark-container">
    <van-nav-bar title="审批列表" />
    <van-tabs v-model="activeIndex" @click="tabSwitch">
      <van-tab v-for="(item, key) in tabList" :key="key" :title="item.title">
      </van-tab>
    </van-tabs>

    <div class="list-wrap">
      <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
        <van-list
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoad"
          :immediate-check="false"
        >
          <van-cell v-for="item in list" @click="info(item.id, item.taskId)">
            <template slot="default">
              <div class="item-wrap">
                <div class="item-header">
                  <img
                    src="https://static.dingtalk.com/media/lALOnahFD80CgM0CgA_640_640.png_450x10000q90.jpg"
                    alt=""
                  />
                  <h3>{{ item.title }}</h3>
                  <span>{{ item.createTime }}</span>
                </div>
                <div class="item-block">
                  <p
                    v-for="(value, key) in item.formValues.formShowData"
                    v-if="key !== '图片'"
                  >
                    {{ key }}：<span v-html="value"></span>
                  </p>
                </div>
                <div class="item-status">
                  <span
                    :class="
                      item.status === 1
                        ? '审批中'
                        : item.status === 2
                        ? 'pass'
                        : 'refused'
                    "
                    >{{
                      item.status === 1
                        ? '审批中'
                        : item.status === 2
                        ? '审批通过'
                        : '审批拒绝'
                    }}</span
                  >
                </div>
              </div>
            </template>
          </van-cell>
        </van-list>
      </van-pull-refresh>
    </div>
  </div>
</template>

<script>
import api from '@/api/process'
import addWatermark from '@/utils/watermark'
import { mapState, mapActions } from 'vuex'
export default {
  name: 'process',

  data() {
    return {
      list: [],
      loading: false,
      finished: false,
      refreshing: false,
      loadingData: false,

      pageNo: 1,
      pageSize: 10,
      pages: 1,

      activeIndex: 0,
      tabList: [
        { title: '待处理' },
        { title: '审批历史' },
        { title: '已发起' },
        { title: '我的抄送' },
      ],
    }
  },

  created() {
    this.activeIndex = parseInt(this.$route.params.activeIndex)
    this.onLoad()
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.userInfo, // 从 Vuex 中获取 userInfo
    }),
  },
  mounted() {
    // 如果 userInfo 没有值，则调用 Vuex action 获取用户信息
    if (!this.userInfo) {
      this.loadUserInfoAndAddWatermark()
    } else {
      // 如果已有 userInfo，直接添加水印
      this.addWatermark()
    }
  },

  methods: {
    // 获取用户信息缓存
    ...mapActions({
      loadUserInfo: 'fetchUserInfo', // 映射 fetchUserInfo 到组件的方法
    }),
    // 异步方法：先加载用户信息，再添加水印
    async loadUserInfoAndAddWatermark() {
      try {
        await this.loadUserInfo() // 等待 Vuex action 完成
        this.addWatermark() // 用户信息加载完成后添加水印
      } catch (error) {
        // console.error('加载用户信息失败:', error)
      }
    },
    addWatermark() {
      // console.log('打印水印数据:' + this.userInfo.name)
      // 调用水印功能
      addWatermark({
        containerSelector: '.watermark-container', // 添加水印的目标容器
        text: '通用锁具 ' + this.userInfo.name, // 自定义水印文本
        font: '16px Arial',
        color: 'rgba(0, 0, 0, 0.15)',
        width: 200,
        height: 150,
        angle: -30,
      })
    },
    tabSwitch() {
      //tab切换，重新初始化数据
      this.list = []
      this.pageNo = 1
      this.finished = false

      //tabs切换时，如果之前的tab已经滚动到底部（list加载到底部），直接点击其他的tab，将再触发一次onload事件。
      //可能调用2次onLoad()方法，延迟执行，通过时间差解决问题
      setTimeout(() => {
        if (!this.finished) {
          this.onLoad()
        }
      }, 500)
    },

    onLoad() {
      // 如果正在加载，直接返回
      if (this.loadingData) return

      this.loadingData = true // 标记为加载状态

      // 根据选中的标签，选择不同的请求方法
      switch (this.activeIndex) {
        case 0:
          return this.findPending() // 异步调用
        case 1:
          return this.findProcessed()
        case 2:
          return this.findStarted()
        case 3:
          return this.carbonCopyList()
        default:
          return Promise.resolve()
      }
    },

    onRefresh() {
      // 清空列表数据
      this.finished = false

      if (this.loadingData) return

      this.pageNo = 1
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true
      this.onLoad().finally(() => {
        this.loadingData = false // 请求完成后清除加载状态
      })
    },

    findPending() {
      console.log(this.pageNo)
      api
        .findPending(this.pageNo, this.pageSize)
        .then((response) => {
          console.log(response.data)
          if (this.refreshing) {
            console.log('刷新列表', this.refreshing)
            this.list = []
            this.refreshing = false
          }
          for (let i = 0; i < response.data.records.length; i++) {
            let item = response.data.records[i]
            item.formValues = JSON.parse(item.formValues)
            this.list.push(item)
          }
          this.pages = response.data.pages

          console.log('刷新后数据', response.data)

          this.loading = false
          if (this.pageNo >= this.pages) {
            this.finished = true
          }
          this.loadingData = false //清除加载状态
          this.pageNo++
        })
        .catch((error) => {
          console.error('请求失败:', error) // 打印异常信息
          this.loadingData = false // 请求失败后清除加载状态
        })
    },

    findProcessed() {
      console.log(this.pageNo)
      api
        .findProcessed(this.pageNo, this.pageSize)
        .then((response) => {
          console.log(response.data)
          if (this.refreshing) {
            this.list = []
            this.refreshing = false
          }
          for (let i = 0; i < response.data.records.length; i++) {
            let item = response.data.records[i]
            item.formValues = JSON.parse(item.formValues)
            this.list.push(item)
          }
          this.pages = response.data.pages

          this.loading = false
          if (this.pageNo >= this.pages) {
            this.finished = true
          }
          this.loadingData = false // 请求失败后清除加载状态
          this.pageNo++
        })
        .catch(() => {
          this.loadingData = false // 请求失败后清除加载状态
        })
    },
    carbonCopyList() {
      api
        .carbonCopyList(this.pageNo, this.pageSize)
        .then((response) => {
          if (this.refreshing) {
            this.list = []
            this.refreshing = false
          }
          for (let i = 0; i < response.data.records.length; i++) {
            let item = response.data.records[i]
            item.formValues = JSON.parse(item.formValues)
            this.list.push(item)
          }
          this.pages = response.data.pages

          this.loading = false
          if (this.pageNo >= this.pages) {
            this.finished = true
          }
          this.loadingData = false // 请求失败后清除加载状态
          this.pageNo++
        })
        .catch(() => {
          this.loadingData = false // 请求失败后清除加载状态
        })
    },

    findStarted() {
      console.log(this.pageNo)
      api
        .findStarted(this.pageNo, this.pageSize)
        .then((response) => {
          console.log(response.data)
          if (this.refreshing) {
            this.list = []
            this.refreshing = false
          }
          for (let i = 0; i < response.data.records.length; i++) {
            let item = response.data.records[i]
            item.formValues = JSON.parse(item.formValues)
            this.list.push(item)
          }
          this.pages = response.data.pages

          this.loading = false
          if (this.pageNo >= this.pages) {
            this.finished = true
          }
          this.loadingData = false // 请求失败后清除加载状态
          this.pageNo++
        })
        .catch(() => {
          this.loadingData = false // 请求失败后清除加载状态
        })
    },

    info(id, taskId) {
      this.$router.push({ path: '/show/' + id + '/' + taskId })
    },
  },
}
</script>

<style lang="scss" scoped>
/deep/ .van-nav-bar {
  background: #1d1e20;
}
/deep/ .van-nav-bar__title {
  color: #fff;
}
.container {
  padding-bottom: 50px;
  .list-wrap {
    margin-top: 4px;
    border-top: 1px solid #ebedf0;
  }
  .item-wrap {
    font-size: 12px;
    color: #a7a8a9;
    .item-header {
      display: flex;
      align-items: center;
      img {
        width: 20px;
        height: 20px;
        border-radius: 4px;
        margin-right: 4px;
      }
      h3 {
        flex: 1;
        font-size: 15px;
        color: #000;
        padding: 0;
        margin: 0;
      }
    }

    .item-block {
      padding: 4px 0;
      font-size: 14px;
      p {
        padding: 0;
        margin: 0;
        line-height: 20px;
      }
    }
    .item-status {
      .pass {
        color: #4cb971;
      }
      .refused {
        color: #eb8473;
      }
    }
  }
}
</style>
